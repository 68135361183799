<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="bracteateType"
    locale-section="pages.bracteates"
    field-section="bracteates"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "BracteateTypeDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: bracteateType
        bracteateType
      `,
      inputAttributes: {
        bracteateType: {
          required: true
        }
      }
    };
  }
};
</script>
